import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Box,
} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import { formatDynamicRoute } from 'utils/common/formatDynamicRoute';
import type { DefaultPopupProps } from 'utils/props';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch, useAppSelector } from 'redux-store';
import type { AddDeviceResponseSuccessType } from 'redux-store/slices/devices/types';
import addDeviceToDeviceGroupThunk from 'redux-store/thunks/deviceGroups/addDeviceToDeviceGroupThunk';
import addDeviceThunk from 'redux-store/thunks/devices/addDeviceThunk';

export interface AddDeviceModalProps extends DefaultPopupProps {
  groupId?: number;
}

const AddDeviceModal: FC<AddDeviceModalProps> = ({
  open,
  onClose,
  groupId,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { t, i18n } = useTranslation();

  const serials = useAppSelector(
    state => state.devices.items.map(device => device.serial),
    (a, b) => a.join() === b.join(),
  );

  const recreatedPath = formatDynamicRoute(router.asPath, router.query);

  const [serialNumber, setSerialNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const serialInputRef = useRef<HTMLInputElement>(null);

  const handleAddDevice = useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();
      if (isLoading) {
        return;
      }

      if (serialNumber.length < 6 || !password.length) {
        return;
      }

      if (serials.includes(serialNumber)) {
        setError(t('common:api.device_already_exists'));

        return;
      }

      try {
        setLoading(true);
        const result = await dispatch(
          addDeviceThunk({ serial: serialNumber, password }),
        ).unwrap();

        if (!result.success) {
          if (i18n.exists(result.error)) {
            setError(t(result.error));
          } else {
            setError(result.error);
          }
        } else {
          if (typeof groupId === 'number' && 'device' in result) {
            await dispatch(
              addDeviceToDeviceGroupThunk({
                deviceGroupId: groupId,
                data: {
                  deviceId: (result as AddDeviceResponseSuccessType).device.id,
                },
              }),
            ).unwrap();
          }
          await router.replace(recreatedPath);
          setSuccess(true);
          setSerialNumber('');
          setPassword('');

          setTimeout(() => {
            onClose();
            setSuccess(false);
          }, 1250);
        }
      } catch (e) {
        // TODO: Take a look at this (sentry etc.)
        Sentry.captureException(e, {
          extra: {
            serialNumber,
          },
          tags: {
            page: 'AddDeviceModal',
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [
      isLoading,
      serialNumber,
      password,
      serials,
      t,
      dispatch,
      i18n,
      groupId,
      router,
      recreatedPath,
      onClose,
    ],
  );

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        serialInputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  const validSerialNumber = useMemo(
    () => serialNumber.length >= 6 && serialNumber.length <= 10,
    [serialNumber],
  );
  const validPassword = useMemo(() => password.length > 0, [password]);
  const valid = useMemo(
    () => validSerialNumber && validPassword && !error,
    [validSerialNumber, validPassword, error],
  );

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box height="100%" sx={{ overflowY: 'auto' }}>
          <form onSubmit={handleAddDevice}>
            <Box mb={3}>
              <Typography
                variant="h2"
                textAlign="center"
                data-testid="new-device-title"
                mb={2}
              >
                {t('common:add_new_device')}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {t('common:add_new_device_description')}
                {typeof groupId === 'number'
                  ? t('common:add_new_device_device_group_description')
                  : null}
              </Typography>
            </Box>
            <Box mb={2}>
              <TextField
                label={t('common:serial_number')}
                name="serial"
                value={serialNumber}
                onChange={(e): void => {
                  setError(null);
                  setSerialNumber(e.target.value);
                }}
                type="text"
                autoComplete="off"
                fullWidth
                inputProps={{
                  maxLength: 10,
                  'data-testid': 'new-device-serial',
                }}
                variant="filled"
                disabled={isLoading}
                inputRef={serialInputRef}
              />
            </Box>
            <Box mb={4}>
              <TextField
                label={t('common:password')}
                name="password"
                value={password}
                onChange={(e): void => {
                  setError(null);
                  setPassword(e.target.value);
                }}
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(): void => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: { 'data-testid': 'new-device-password' },
                }}
                fullWidth
                variant="filled"
                disabled={isLoading}
              />
            </Box>
            <Box mb={2}>
              <Button
                fullWidth
                variant="flat"
                type="submit"
                disabled={!valid || isLoading}
                onClick={handleAddDevice}
                size="large"
                data-testid="submit"
              >
                {t('common:add_device')}
              </Button>
            </Box>
            <ErrorText error={error} />
          </form>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography variant="h1" margin="20px 10px" textAlign="center">
              {t('common:device_added')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default AddDeviceModal;
