import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import type { Action } from 'redux';

import apiReducer from 'redux-store/slices/api';
import deviceGroupsReducer from 'redux-store/slices/deviceGroups';
import devicesReducer from 'redux-store/slices/devices';
import groupConfigDevicesReducer from 'redux-store/slices/groupConfigDevices';
import groupSummaryDevicesReducer from 'redux-store/slices/groupSummaryDevices';
import profileReducer from 'redux-store/slices/profile';
import rfidGroupsReducer from 'redux-store/slices/rfidGroups';
import rfidsReducer from 'redux-store/slices/rfids';
import setupDevicesReducer from 'redux-store/slices/setupDevices';
import uiReducer from 'redux-store/slices/ui';

const reducer = combineReducers({
  profile: profileReducer,
  devices: devicesReducer,
  deviceGroups: deviceGroupsReducer,
  rfidGroups: rfidGroupsReducer,
  rfids: rfidsReducer,
  setupDevices: setupDevicesReducer,
  groupConfigDevices: groupConfigDevicesReducer,
  groupSummaryDevices: groupSummaryDevicesReducer,
  api: apiReducer,
  ui: uiReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeStore = () => {
  return configureStore({
    reducer,
    devTools:
      process.env.NODE_ENV !== 'production'
        ? {
            actionsDenylist: ['api/getApiMessage'],
          }
        : false,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['api/initTunnel'],
          ignoredPaths: ['api.ws'],
          ignoreState: true,
          warnAfter: 100,
        },
      }),
  });
};

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
// export type AppThunk<ReturnType> = ThunkAction<
//   ReturnType,
//   AppState,
//   unknown,
//   Action
// >;

export interface HydrateAction<T> extends Action {
  payload: {
    [stateName: string]: T;
  };
}

export const wrapper = createWrapper<AppStore>(makeStore, {
  debug: false,
});

export type AppDispatch = AppStore['dispatch'];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
