import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import pageUrls from 'constants/pageUrls';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import CustomTrashIcon from 'components/Icons/CustomTrashIcon';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch } from 'redux-store';
import { removeRfidOverviewSelectedRfid } from 'redux-store/slices/ui';
import type { RfidId } from 'redux-store/slices/ui/types';
import deleteRfidThunk from 'redux-store/thunks/rfids/deleteRfidThunk';

interface Props extends DefaultPopupProps {
  rfidIds: RfidId[] | null;
}

const RemoveMultipleRfidsModal: FC<Props> = ({ onClose, rfidIds, open }) => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { t } = useTranslation();

  const [, setRfidStates] = useState<Record<string, string | boolean | null>>(
    {},
  );

  const removeRfids = useCallback(async () => {
    if (!rfidIds?.length) return;

    // set all to false
    setRfidStates(
      rfidIds.reduce((acc, rfid) => ({ ...acc, [rfid]: null }), {}),
    );

    const handleDeleteRfid = async (rfidId: RfidId): Promise<boolean> => {
      try {
        setRfidStates(prev => ({ ...prev, [rfidId]: false }));
        const result = await dispatch(deleteRfidThunk(rfidId)).unwrap();

        if (!result.success) {
          setRfidStates(prev => ({
            ...prev,
            [rfidId]: t('common:pages.rfids.remove_rfid_error'),
          }));
        } else {
          setRfidStates(prev => ({ ...prev, [rfidId]: true }));

          dispatch(removeRfidOverviewSelectedRfid(rfidId));

          return true;
        }
      } catch (e) {
        console.error('error deleting rfid', e);
        setRfidStates(prev => ({
          ...prev,
          [rfidId]: t('common:pages.rfids.remove_rfid_error'),
        }));
      }

      return false;
    };

    setLoading(true);

    let deleteSuccess = true;

    for await (const rfidNumber of rfidIds) {
      if (!(await handleDeleteRfid(rfidNumber))) {
        deleteSuccess = false;
      }
    }

    if (deleteSuccess) {
      setSuccess(true);

      setError(null);

      setTimeout(async () => {
        await router.push(pageUrls.rfids.all);
        onClose();
        setSuccess(false);
      }, 1000);
    } else {
      setError(t('common:pages.rfids.remove_rfid_error'));
    }

    setLoading(false);
  }, [dispatch, rfidIds, t, onClose, router]);

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ overflowY: 'auto' }}
        >
          <Box mb={4}>
            <Typography
              variant="h2"
              textAlign="center"
              data-testid="remove-multiple-rfid-chips-modal"
              mb={2}
            >
              {t('common:pages.rfid_groups.remove_multiple_rfids')}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t('common:pages.rfid_groups.remove_multiple_rfids_description')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" mt={3} gap={1} width="100%">
            <Button
              onClick={onClose}
              color="neutral"
              fullWidth
              variant="flat"
              size="large"
              data-testid="cancel-button"
            >
              {t('common:cancel')}
            </Button>
            <Button
              disabled={isLoading}
              onClick={removeRfids}
              fullWidth
              variant="flat"
              size="large"
              color="error"
              data-testid="submit"
            >
              <CustomTrashIcon sx={{ mr: 1 }} />
              {t('common:pages.rfid_groups.deleteMultipleRfidsButton')}
            </Button>
          </Box>
          <ErrorText error={error} mt={2} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              data-testid="success-text"
            >
              {t('common:pages.rfids.multiple_rfids_removed')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RemoveMultipleRfidsModal;
