import { Box, TextField, Typography } from '@mui/material';
import useDeviceGroup from 'hooks/useDeviceGroup';
import { useTranslation } from 'next-i18next';
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch } from 'redux-store';
import type { DeviceGroupId } from 'redux-store/slices/deviceGroups/types';
import getAllDeviceGroupsThunk from 'redux-store/thunks/deviceGroups/getAllDeviceGroupsThunk';
import renameDeviceGroupThunk from 'redux-store/thunks/deviceGroups/renameDeviceGroupThunk';

export interface RenameDeviceGroupModalProps extends DefaultPopupProps {
  groupId: DeviceGroupId | null;
}

const RenameDeviceGroupModal: FC<RenameDeviceGroupModalProps> = ({
  groupId,
  onClose,
  open,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const groupNameInputRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const deviceGroup = useDeviceGroup(groupId ?? undefined);

  const currentName = useMemo(() => deviceGroup?.name ?? '', [deviceGroup]);

  const [name, setName] = useState<string>(currentName);

  useEffect(() => {
    if (currentName) {
      setName(currentName);
    }
  }, [currentName]);

  const handleSave = useCallback(async () => {
    if (isLoading) {
      return;
    }

    if (!name || groupId === null) {
      return;
    }

    const result = await dispatch(
      renameDeviceGroupThunk({ deviceGroupId: groupId, data: { name } }),
    ).unwrap();

    if (result.success) {
      await dispatch(getAllDeviceGroupsThunk({})).unwrap();

      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 1250);
    } else {
      setError(result.error);
    }

    setLoading(false);
  }, [dispatch, groupId, isLoading, name, onClose]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        groupNameInputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      {!success ? (
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ overflowY: 'auto' }}
        >
          <Box mb={4} width="100%">
            <Typography
              variant="h2"
              textAlign="center"
              mb={2}
              data-testid="rename-device-group-modal"
            >
              {t('common:pages.device_groups.rename_group.title')}
            </Typography>
            <Typography variant="body1" mb={2} textAlign="center">
              {t('common:pages.device_groups.rename_group.description')}
            </Typography>
          </Box>
          <Box mb={4} width="100%">
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSave();
              }}
            >
              <TextField
                variant="filled"
                fullWidth
                label={t('common:pages.device_groups.rename_group.group_name')}
                value={name}
                onChange={e => setName(e.target.value)}
                inputProps={{ 'data-testid': 'new-name-input' }}
                error={Boolean(error)}
                helperText={error}
                inputRef={groupNameInputRef}
              />
            </form>
          </Box>
          <ErrorText error={error} mb={2} />
          <Box width="100%" display="flex" flexDirection="row" gap={2}>
            <Button
              variant="flat"
              color="neutral"
              fullWidth
              onClick={onClose}
              size="large"
              data-testid="cancel-button"
            >
              {t('common:cancel')}
            </Button>
            <Button
              variant="flat"
              fullWidth
              size="large"
              onClick={handleSave}
              disabled={isLoading}
              data-testid="submit"
            >
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              textAlign="center"
              data-testid="success-text"
            >
              {t('common:pages.device_groups.rename_group.group_renamed')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RenameDeviceGroupModal;
