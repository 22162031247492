import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface GroupSummaryDevicesType {
  serial: string | undefined;
  energyTotal: number;
  powerTotal: number;
  homePowerTotal: number;
}

interface GroupSummaryState {
  data: GroupSummaryDevicesType[];
}

const initialState: GroupSummaryState = {
  data: [],
};

const GroupSummarySlice = createSlice({
  name: 'GroupSummaryDevices',
  initialState,
  reducers: {
    groupSummaryDevices: (
      state,
      action: PayloadAction<GroupSummaryDevicesType[]>,
    ) => {
      action.payload.forEach(newDevice => {
        const existingDevice = state.data.find(
          device => device.serial === newDevice.serial,
        );

        if (existingDevice) {
          existingDevice.energyTotal = newDevice.energyTotal;
          existingDevice.powerTotal = newDevice.powerTotal;
          existingDevice.homePowerTotal = newDevice.homePowerTotal;
        } else {
          state.data.push(newDevice);
        }
      });
    },
  },
});

// Export actions
export const { groupSummaryDevices } = GroupSummarySlice.actions;

// Export reducer
export default GroupSummarySlice.reducer;
