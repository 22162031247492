import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { HydrateAction } from 'redux-store';

import type { DeviceSerial, RfidId, UIStateType } from './types';

const initialState: UIStateType = {
  canGoBack: false,
  settingsTab: null,
  loginTransition: false,
  addDeviceModal: {
    open: false,
    groupId: null,
  },
  removeDeviceModal: null,
  removeMultipleDevicesModal: null,
  showLeaveGroupModal: null,
  showAddDevicesToGroupsModal: null,
  rebootDeviceModal: null,
  preferencesModal: false,
  profileModal: false,
  selectedLicense: null,
  deviceOverview: {
    deviceTypeFilter: 'all',
    viewMode: 'standard',
    searchSerials: null,
    editMode: false,
    selectedDevices: [],
  },
  deviceGroups: {
    addDeviceGroupModal: false,
    removeDeviceGroupModal: null,
    renameDeviceGroupModal: null,
    searchGroups: null,
  },
  deviceGroup: {
    deviceTypeFilter: 'all',
    viewMode: 'standard',
    searchSerials: null,
    editMode: false,
    selectedDevices: [],
  },
  deviceGroupConfigModal: {
    open: false,
    deviceSerialNumbers: null,
  },
  addRfidModal: {
    open: false,
    groupId: null,
  },
  updateRfidModal: 0,
  removeRfidModal: null,
  removeMultipleRfidsModal: null,
  rfidGroups: {
    addRfidGroupModal: false,
    removeRfidGroupModal: null,
    renameRfidGroupModal: null,
    searchGroups: null,
  },
  rfidGroup: {
    viewMode: 'standard',
    searchRfids: null,
    editMode: false,
    selectedRfids: [],
  },
  rfidOverview: {
    viewMode: 'standard',
    searchRfids: null,
    editMode: false,
    selectedRfids: [],
  },
  technicianPasswordVerified: false,
  deviceTroubleshootingModal: {
    open: false,
    deviceSerialNumber: null,
  },
};

const UiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    canGoBack: state => {
      state.canGoBack = true;
    },
    setSettingsTab: (
      state,
      action: PayloadAction<UIStateType['settingsTab']>,
    ) => {
      state.settingsTab = action.payload;
    },
    setLoginTransition: (
      state,
      action: PayloadAction<UIStateType['loginTransition']>,
    ) => {
      state.loginTransition = action.payload;
    },
    setAddDeviceModal: (
      state,
      action: PayloadAction<UIStateType['addDeviceModal']>,
    ) => {
      state.addDeviceModal = action.payload;
    },
    setRemoveDeviceModal: (
      state,
      action: PayloadAction<UIStateType['removeDeviceModal']>,
    ) => {
      state.removeDeviceModal = action.payload;
    },
    setRemoveMultipleDevicesModal: (
      state,
      action: PayloadAction<UIStateType['removeMultipleDevicesModal']>,
    ) => {
      state.removeMultipleDevicesModal = action.payload;
    },
    setShowLeaveGroupModal: (
      state,
      action: PayloadAction<UIStateType['showLeaveGroupModal']>,
    ) => {
      state.showLeaveGroupModal = action.payload;
    },
    setAddDevicesToGroupsModal: (
      state,
      action: PayloadAction<UIStateType['showAddDevicesToGroupsModal']>,
    ) => {
      state.showAddDevicesToGroupsModal = action.payload;
    },
    setRebootDeviceModal: (
      state,
      action: PayloadAction<UIStateType['rebootDeviceModal']>,
    ) => {
      state.rebootDeviceModal = action.payload;
    },
    setPreferencesModal: (
      state,
      action: PayloadAction<UIStateType['preferencesModal']>,
    ) => {
      state.preferencesModal = action.payload;
    },
    setProfileModal: (
      state,
      action: PayloadAction<UIStateType['profileModal']>,
    ) => {
      state.profileModal = action.payload;
    },
    setDeviceOverviewDeviceTypeFilter: (
      state,
      action: PayloadAction<UIStateType['deviceOverview']['deviceTypeFilter']>,
    ) => {
      state.deviceOverview.deviceTypeFilter = action.payload;
    },
    setDeviceOverviewViewMode: (
      state,
      action: PayloadAction<UIStateType['deviceOverview']['viewMode']>,
    ) => {
      state.deviceOverview.viewMode = action.payload;
    },
    setDeviceOverviewSearch: (
      state,
      action: PayloadAction<UIStateType['deviceOverview']['searchSerials']>,
    ) => {
      state.deviceOverview.searchSerials = action.payload;
    },
    setDeviceOverviewEditMode: (
      state,
      action: PayloadAction<UIStateType['deviceOverview']['editMode']>,
    ) => {
      state.deviceOverview.editMode = action.payload;
    },
    setDeviceOverviewSelectedDevices: (
      state,
      action: PayloadAction<UIStateType['deviceOverview']['selectedDevices']>,
    ) => {
      state.deviceOverview.selectedDevices = action.payload;
    },
    addDeviceOverviewSelectedDevice: (
      state,
      action: PayloadAction<DeviceSerial>,
    ) => {
      if (!state.deviceOverview.selectedDevices.includes(action.payload)) {
        state.deviceOverview.selectedDevices.push(action.payload);
      }
    },
    removeDeviceOverviewSelectedDevice: (
      state,
      action: PayloadAction<DeviceSerial>,
    ) => {
      state.deviceOverview.selectedDevices =
        state.deviceOverview.selectedDevices.filter(
          id => id !== action.payload,
        );
    },
    setAddDeviceGroupModal: (
      state,
      action: PayloadAction<UIStateType['deviceGroups']['addDeviceGroupModal']>,
    ) => {
      state.deviceGroups.addDeviceGroupModal = action.payload;
    },
    setRemoveDeviceGroupModal: (
      state,
      action: PayloadAction<
        UIStateType['deviceGroups']['removeDeviceGroupModal']
      >,
    ) => {
      state.deviceGroups.removeDeviceGroupModal = action.payload;
    },
    setRenameDeviceGroupModal: (
      state,
      action: PayloadAction<
        UIStateType['deviceGroups']['renameDeviceGroupModal']
      >,
    ) => {
      state.deviceGroups.renameDeviceGroupModal = action.payload;
    },
    setDeviceGroupsSearch: (
      state,
      action: PayloadAction<UIStateType['deviceGroups']['searchGroups']>,
    ) => {
      state.deviceGroups.searchGroups = action.payload;
    },
    setDeviceGroupDeviceTypeFilter: (
      state,
      action: PayloadAction<UIStateType['deviceGroup']['deviceTypeFilter']>,
    ) => {
      state.deviceGroup.deviceTypeFilter = action.payload;
    },
    setDeviceGroupViewMode: (
      state,
      action: PayloadAction<UIStateType['deviceGroup']['viewMode']>,
    ) => {
      state.deviceGroup.viewMode = action.payload;
    },
    setDeviceGroupSearch: (
      state,
      action: PayloadAction<UIStateType['deviceGroup']['searchSerials']>,
    ) => {
      state.deviceGroup.searchSerials = action.payload;
    },
    setDeviceGroupEditMode: (
      state,
      action: PayloadAction<UIStateType['deviceGroup']['editMode']>,
    ) => {
      state.deviceGroup.editMode = action.payload;
    },
    setDeviceGroupSelectedDevices: (
      state,
      action: PayloadAction<UIStateType['deviceGroup']['selectedDevices']>,
    ) => {
      state.deviceGroup.selectedDevices = action.payload;
    },
    addDeviceGroupSelectedDevice: (
      state,
      action: PayloadAction<DeviceSerial>,
    ) => {
      if (!state.deviceGroup.selectedDevices.includes(action.payload)) {
        state.deviceGroup.selectedDevices.push(action.payload);
      }
    },
    removeDeviceGroupSelectedDevice: (
      state,
      action: PayloadAction<DeviceSerial>,
    ) => {
      state.deviceGroup.selectedDevices =
        state.deviceGroup.selectedDevices.filter(id => id !== action.payload);
    },
    setDeviceGroupConfigModal: (
      state,
      action: PayloadAction<UIStateType['deviceGroupConfigModal']>,
    ) => {
      state.deviceGroupConfigModal = action.payload;
    },
    setDeviceTroubleshootingModal: (
      state,
      action: PayloadAction<UIStateType['deviceTroubleshootingModal']>,
    ) => {
      state.deviceTroubleshootingModal = action.payload;
    },
    setSelectedLicense: (
      state,
      action: PayloadAction<UIStateType['selectedLicense']>,
    ) => {
      state.selectedLicense = action.payload;
    },
    // Rfids
    setRfidOverviewViewMode: (
      state,
      action: PayloadAction<UIStateType['rfidOverview']['viewMode']>,
    ) => {
      state.rfidOverview.viewMode = action.payload;
    },
    setRfidOverviewSearch: (
      state,
      action: PayloadAction<UIStateType['rfidOverview']['searchRfids']>,
    ) => {
      state.rfidOverview.searchRfids = action.payload;
    },
    setRfidOverviewEditMode: (
      state,
      action: PayloadAction<UIStateType['rfidOverview']['editMode']>,
    ) => {
      state.rfidOverview.editMode = action.payload;
    },
    setRfidOverviewSelectedRfids: (
      state,
      action: PayloadAction<UIStateType['rfidOverview']['selectedRfids']>,
    ) => {
      state.rfidOverview.selectedRfids = action.payload;
    },
    addRfidOverviewSelectedRfid: (state, action: PayloadAction<RfidId>) => {
      if (!state.rfidOverview.selectedRfids.includes(action.payload)) {
        state.rfidOverview.selectedRfids.push(action.payload);
      }
    },
    removeRfidOverviewSelectedRfid: (state, action: PayloadAction<RfidId>) => {
      state.rfidOverview.selectedRfids =
        state.rfidOverview.selectedRfids.filter(id => id !== action.payload);
    },
    // Rfid Group
    addRfidGroupSelectedRfid: (state, action: PayloadAction<RfidId>) => {
      if (!state.rfidGroup.selectedRfids.includes(action.payload)) {
        state.rfidGroup.selectedRfids.push(action.payload);
      }
    },
    removeRfidGroupSelectedRfid: (state, action: PayloadAction<RfidId>) => {
      state.rfidGroup.selectedRfids = state.rfidGroup.selectedRfids.filter(
        id => id !== action.payload,
      );
    },
    setAddRfidModal: (
      state,
      action: PayloadAction<UIStateType['addRfidModal']>,
    ) => {
      state.addRfidModal = action.payload;
    },
    setUpdateRfidModal: (
      state,
      action: PayloadAction<UIStateType['updateRfidModal']>,
    ) => {
      state.updateRfidModal = action.payload;
    },

    setRemoveRfidModal: (
      state,
      action: PayloadAction<UIStateType['removeRfidModal']>,
    ) => {
      state.removeRfidModal = action.payload;
    },
    setRemoveMultipleRfidsModal: (
      state,
      action: PayloadAction<UIStateType['removeMultipleRfidsModal']>,
    ) => {
      state.removeMultipleRfidsModal = action.payload;
    },
    setRfidGroupEditMode: (
      state,
      action: PayloadAction<UIStateType['rfidGroup']['editMode']>,
    ) => {
      state.rfidGroup.editMode = action.payload;
    },
    setRfidGroupSearch: (
      state,
      action: PayloadAction<UIStateType['rfidGroup']['searchRfids']>,
    ) => {
      state.rfidGroup.searchRfids = action.payload;
    },
    setRfidGroupSelectedRfids: (
      state,
      action: PayloadAction<UIStateType['rfidGroup']['selectedRfids']>,
    ) => {
      state.rfidGroup.selectedRfids = action.payload;
    },
    setRfidGroupViewMode: (
      state,
      action: PayloadAction<UIStateType['rfidGroup']['viewMode']>,
    ) => {
      state.rfidGroup.viewMode = action.payload;
    },
    // Rfid group view
    setAddRfidGroupModal: (
      state,
      action: PayloadAction<UIStateType['rfidGroups']['addRfidGroupModal']>,
    ) => {
      state.rfidGroups.addRfidGroupModal = action.payload;
    },
    setRemoveRfidGroupModal: (
      state,
      action: PayloadAction<UIStateType['rfidGroups']['removeRfidGroupModal']>,
    ) => {
      state.rfidGroups.removeRfidGroupModal = action.payload;
    },
    setRenameRfidGroupModal: (
      state,
      action: PayloadAction<UIStateType['rfidGroups']['renameRfidGroupModal']>,
    ) => {
      state.rfidGroups.renameRfidGroupModal = action.payload;
    },
    setRfidGroupsSearch: (
      state,
      action: PayloadAction<UIStateType['rfidGroups']['searchGroups']>,
    ) => {
      state.rfidGroups.searchGroups = action.payload;
    },
    setTechnicianPasswordVerified: (
      state,
      action: PayloadAction<UIStateType['technicianPasswordVerified']>,
    ) => {
      state.technicianPasswordVerified = action.payload;
    },
  },
  extraReducers: builder => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(HYDRATE, (state, action: HydrateAction<UIStateType>) => {
      return action.payload.ui;
    });
  },
});

export const {
  canGoBack,
  setSettingsTab,
  setLoginTransition,
  setAddDeviceModal,
  setRemoveDeviceModal,
  setRemoveMultipleDevicesModal,
  setRemoveDeviceGroupModal,
  setShowLeaveGroupModal,
  setAddDevicesToGroupsModal,
  setRebootDeviceModal,
  setPreferencesModal,
  setProfileModal,
  setDeviceOverviewDeviceTypeFilter,
  setDeviceOverviewViewMode,
  setDeviceOverviewSearch,
  setDeviceOverviewEditMode,
  setDeviceOverviewSelectedDevices,
  addDeviceOverviewSelectedDevice,
  removeDeviceOverviewSelectedDevice,
  setAddDeviceGroupModal,
  setRenameDeviceGroupModal,
  setDeviceGroupsSearch,
  setDeviceGroupDeviceTypeFilter,
  setDeviceGroupViewMode,
  setDeviceGroupSearch,
  setDeviceGroupEditMode,
  setDeviceGroupSelectedDevices,
  addDeviceGroupSelectedDevice,
  removeDeviceGroupSelectedDevice,
  setDeviceGroupConfigModal,
  setDeviceTroubleshootingModal,
  setSelectedLicense,
  setAddRfidGroupModal,
  setRemoveRfidGroupModal,
  setRemoveMultipleRfidsModal,
  setRenameRfidGroupModal,
  setRfidGroupsSearch,
  setRfidOverviewViewMode,
  setRfidOverviewSearch,
  setRfidOverviewEditMode,
  setRfidOverviewSelectedRfids,
  addRfidOverviewSelectedRfid,
  removeRfidOverviewSelectedRfid,
  addRfidGroupSelectedRfid,
  removeRfidGroupSelectedRfid,
  setAddRfidModal,
  setUpdateRfidModal,
  setRfidGroupEditMode,
  setRfidGroupSearch,
  setRemoveRfidModal,
  setRfidGroupSelectedRfids,
  setRfidGroupViewMode,
  setTechnicianPasswordVerified,
} = UiSlice.actions;

export default UiSlice.reducer;
