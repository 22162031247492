import { Box, Typography } from '@mui/material';
import useDeviceGroup from 'hooks/useDeviceGroup';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import pageUrls from 'constants/pageUrls';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';
import GenericSettingComponent from 'components/SettingComponents/GenericSettingComponent';

import { useAppDispatch } from 'redux-store';
import type { DeviceGroupId } from 'redux-store/slices/deviceGroups/types';
import deleteDeviceGroupThunk from 'redux-store/thunks/deviceGroups/deleteDeviceGroupThunk';
import getAllDeviceGroupsThunk from 'redux-store/thunks/deviceGroups/getAllDeviceGroupsThunk';

export interface RemoveDeviceGroupModalProps extends DefaultPopupProps {
  groupId: DeviceGroupId | null;
}

type ErrorMessage = string;
type Successful = true;
type Loading = false;
type Nothing = null;

type GroupState = ErrorMessage | Successful | Loading | Nothing;

const RemoveDeviceGroupModal: FC<RemoveDeviceGroupModalProps> = ({
  onClose,
  groupId,
  open,
}) => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { t } = useTranslation();

  const [groupState, setGroupState] = useState<GroupState>(null);

  const removeDeviceGroups = useCallback(async () => {
    if (groupId === null) return;

    setGroupState(null);

    const handleDeleteDeviceGroup = async (): Promise<boolean> => {
      try {
        setGroupState(false);
        const result = await dispatch(
          deleteDeviceGroupThunk({ groupId }),
        ).unwrap();

        if (!result.success) {
          setGroupState(t('common:pages.settings.remove_device_group_error'));
        } else {
          setGroupState(true);

          return true;
        }
      } catch (e) {
        console.error('error deleting device', e);
        setGroupState(t('common:pages.settings.remove_device_group_error'));
      }

      return false;
    };

    setLoading(true);

    if (await handleDeleteDeviceGroup()) {
      setSuccess(true);

      setError(null);

      await dispatch(getAllDeviceGroupsThunk({})).unwrap();

      setTimeout(async () => {
        await router.push(pageUrls.deviceGroups.all);
        onClose();
        setSuccess(false);
      }, 1000);
    } else {
      setError(t('common:pages.settings.remove_device_group_error'));
    }

    setLoading(false);
  }, [dispatch, groupId, t, onClose, router]);

  const deviceGroup = useDeviceGroup(groupId ?? undefined);

  if (!deviceGroup) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ overflowY: 'auto' }}
        >
          <Box mb={4}>
            <Typography
              variant="h2"
              textAlign="center"
              data-testid="remove-device-group-modal"
              mb={2}
            >
              {t('common:pages.settings.remove_device_group')}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t('common:pages.settings.remove_device_group_description')}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            overflow="auto"
            flexGrow={0}
            flexShrink={1}
            flexBasis="auto"
            width="100%"
            mb={4}
          >
            <GenericSettingComponent
              fullWidth
              leftText={
                typeof groupState === 'string'
                  ? (groupState as string)
                  : deviceGroup.name
              }
              passableProps={null}
              disableHideWhenOffline
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={1} width="100%">
            <Button
              onClick={onClose}
              color="neutral"
              fullWidth
              size="large"
              variant="flat"
              data-testid="cancel-button"
            >
              {t('common:cancel')}
            </Button>
            <Button
              disabled={isLoading}
              onClick={removeDeviceGroups}
              fullWidth
              variant="flat"
              size="large"
              color="error"
              data-testid="submit"
            >
              {t('common:pages.device_groups.remove_group')}
            </Button>
          </Box>
          <ErrorText error={error} mt={2} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              data-testid="success-text"
            >
              {t('common:pages.settings.device_group_removed')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RemoveDeviceGroupModal;
