import BugReportIcon from '@mui/icons-material/BugReport';
import { Box, Fab, useTheme } from '@mui/material';
import AddDeviceGroupModal from 'content/modals/AddDeviceGroupModal';
import AddDeviceModal from 'content/modals/AddDeviceModal';
import AddRfidGroupModal from 'content/modals/AddRfidGroupModal';
import AddRfidModal from 'content/modals/AddRfidModal';
import DebugModal from 'content/modals/DebugModal';
import DeviceGroupConfigModal from 'content/modals/DeviceGroupConfigModal';
import PreferencesModal from 'content/modals/PreferencesModal';
import ProfileModal from 'content/modals/ProfileModal';
import RebootDeviceModal from 'content/modals/RebootDeviceModal';
import RemoveDeviceGroupModal from 'content/modals/RemoveDeviceGroupModal';
import RemoveDeviceModal from 'content/modals/RemoveDeviceModal';
import RemoveMultipleDevicesModal from 'content/modals/RemoveMultipleDevicesModal';
import RemoveMultipleRfidsModal from 'content/modals/RemoveMultipleRfidsModal';
import RemoveRfidGroupModal from 'content/modals/RemoveRfidGroupModal';
import RemoveRfidModal from 'content/modals/RemoveRfidModal';
import RenameDeviceGroupModal from 'content/modals/RenameDeviceGroupModal';
import RenameRfidGroupModal from 'content/modals/RenameRfidGroupModal';
import TroubleshootingDevicesModal from 'content/modals/TroubleshootingDevicesModal';
import UpdateRfidModal from 'content/modals/UpdateRfidModal';
import { HtmlHead } from 'layouts/HtmlHead';
import Sidebar from 'layouts/Sidebar';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';

import pageUrls from 'constants/pageUrls';

import { useAppSelector, useAppDispatch } from 'redux-store';
import {
  setAddDeviceGroupModal,
  setAddDeviceModal,
  setAddRfidGroupModal,
  setAddRfidModal,
  setRebootDeviceModal,
  setRemoveDeviceGroupModal,
  setRenameDeviceGroupModal,
  setRemoveDeviceModal,
  setRemoveMultipleDevicesModal,
  setUpdateRfidModal,
  setRemoveRfidModal,
  setRemoveRfidGroupModal,
  setRenameRfidGroupModal,
  setDeviceGroupConfigModal,
  setDeviceTroubleshootingModal,
  canGoBack,
  setRemoveMultipleRfidsModal,
} from 'redux-store/slices/ui';
import type { DeviceSerial, RfidId } from 'redux-store/slices/ui/types';

export interface DashboardWrapperProps {
  children: ReactNode;
  seo?: {
    title?: string;
    description?: string;
  };
}

const { publicRuntimeConfig } = getConfig();

const { BUILD_ENV, ENABLE_HIDE_DURING_PAGE_TRANSITION } = publicRuntimeConfig;

const DEFAULT_HIDE_FAB = BUILD_ENV !== 'development';
const DISABLE_HIDE_DURING_PAGE_TRANSITION =
  ENABLE_HIDE_DURING_PAGE_TRANSITION !== 'true';

const DashboardWrapper: FC<DashboardWrapperProps> = ({ children, seo }) => {
  const theme = useTheme();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const [hideFab, setHideFab] = useState(DEFAULT_HIDE_FAB);

  const showDebugFab = process.env.BUILD_ENV !== 'production' && !hideFab;

  useEffect(() => {
    // when query param "kasperl" is present and set to specific value, show debug modal
    if (router.query.kasperl === 'Tri-tra-trullalla') {
      setHideFab(false);
    }
  }, [router.query.kasperl]);

  const [debugModalOpen, setDebugModalOpen] = useState(false);

  const toggleDebugModalOpen = (): void => setDebugModalOpen(prev => !prev);

  const [transitioning, setTransitioning] = useState(false);

  const showAddDeviceModal = useAppSelector(state => state.ui.addDeviceModal);
  const removeDeviceState = useAppSelector(state => state.ui.removeDeviceModal);
  const rebootDeviceState = useAppSelector(state => state.ui.rebootDeviceModal);
  const removeMultipleDevicesState = useAppSelector(
    state => state.ui.removeMultipleDevicesModal,
  );
  const removeDeviceGroupState = useAppSelector(
    state => state.ui.deviceGroups.removeDeviceGroupModal,
  );
  const renameDeviceGroupState = useAppSelector(
    state => state.ui.deviceGroups.renameDeviceGroupModal,
  );
  const showAddDeviceGroupModal = useAppSelector(
    state => state.ui.deviceGroups.addDeviceGroupModal,
  );

  const showDeviceGroupConfigModal = useAppSelector(
    state => state.ui.deviceGroupConfigModal,
  );

  const showDeviceTroubleshootingModal = useAppSelector(
    state => state.ui.deviceTroubleshootingModal,
  );

  const showAddRfidModal = useAppSelector(state => state.ui.addRfidModal);
  const showAddRfidGroupModal = useAppSelector(
    state => state.ui.rfidGroups.addRfidGroupModal,
  );
  const showUpdateRfidModal = useAppSelector(state => state.ui.updateRfidModal);

  const removeRfidState = useAppSelector(state => state.ui.removeRfidModal);

  const removeMultipleRfidsState = useAppSelector(
    state => state.ui.removeMultipleRfidsModal,
  );

  const removeRfidGroupState = useAppSelector(
    state => state.ui.rfidGroups.removeRfidGroupModal,
  );
  const renameRfidGroupState = useAppSelector(
    state => state.ui.rfidGroups.renameRfidGroupModal,
  );

  const closeAddDeviceModal = useCallback(() => {
    dispatch(setAddDeviceModal({ open: false, groupId: null }));
  }, [dispatch]);

  const closeRemoveDeviceModal = useCallback(() => {
    dispatch(setRemoveDeviceModal(null));
  }, [dispatch]);

  const closeRemoveMultipleDevicesModal = useCallback(() => {
    dispatch(setRemoveMultipleDevicesModal(null));
  }, [dispatch]);

  const closeRemoveDeviceGroupModal = useCallback(() => {
    dispatch(setRemoveDeviceGroupModal(null));
  }, [dispatch]);

  const closeRenameDeviceGroupModal = useCallback(() => {
    dispatch(setRenameDeviceGroupModal(null));
  }, [dispatch]);

  const closeRebootDeviceModal = useCallback(() => {
    dispatch(setRebootDeviceModal(null));
  }, [dispatch]);

  const handleHideAddDeviceGroupModal = useCallback(() => {
    dispatch(setAddDeviceGroupModal(false));
  }, [dispatch]);

  const closeDeviceGroupConfigModal = useCallback(() => {
    dispatch(
      setDeviceGroupConfigModal({ open: false, deviceSerialNumbers: null }),
    );
  }, [dispatch]);

  const closeDeviceTroubleshootingModal = useCallback(() => {
    dispatch(
      setDeviceTroubleshootingModal({
        open: false,
        deviceSerialNumber: null,
      }),
    );
  }, [dispatch]);

  const closeAddRfidModal = useCallback(() => {
    dispatch(setAddRfidModal({ open: false, groupId: null }));
  }, [dispatch]);

  const closeUpdateRfidModal = useCallback(() => {
    dispatch(setUpdateRfidModal(0));
  }, [dispatch]);

  const closeRemoveRfidModal = useCallback(() => {
    dispatch(setRemoveRfidModal(null));
  }, [dispatch]);

  const closeRemoveMultipleRfidsModal = useCallback(() => {
    dispatch(setRemoveMultipleRfidsModal(null));
  }, [dispatch]);

  const handleHideAddRfidGroupModal = useCallback(() => {
    dispatch(setAddRfidGroupModal(false));
  }, [dispatch]);

  const closeRemoveRfidGroupModal = useCallback(() => {
    dispatch(setRemoveRfidGroupModal(null));
  }, [dispatch]);

  const closeRenameRfidGroupModal = useCallback(() => {
    dispatch(setRenameRfidGroupModal(null));
  }, [dispatch]);

  useEffect(() => {
    const handleRouteDone = (): void => {
      dispatch(canGoBack());
    };

    router.events.on('routeChangeComplete', handleRouteDone);

    return () => {
      router.events.off('routeChangeComplete', handleRouteDone);
    };
  }, [dispatch, router.events]);

  useEffect(() => {
    if (DISABLE_HIDE_DURING_PAGE_TRANSITION) {
      return;
    }

    const handleRouteDone = (): void => {
      setTransitioning(false);
    };

    const handleRouteStart = (): void => {
      setTransitioning(true);
    };

    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteDone);
    router.events.on('routeChangeError', handleRouteDone);

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteDone);
      router.events.off('routeChangeError', handleRouteDone);
    };
  });

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] =
    useState<boolean>(false);

  return (
    <>
      <HtmlHead {...seo} />
      <Box
        display="flex"
        alignItems="self-start"
        width="100%"
        minWidth="100%"
        height="100%"
      >
        {router.pathname !== pageUrls.setupDevices ? (
          <Sidebar
            onSidebarClose={() => setIsMobileSidebarOpen(false)}
            isMobileSidebarOpen={isMobileSidebarOpen}
          />
        ) : null}
        <Box
          gridArea="content"
          display="flex"
          flexDirection="column"
          //padding={hideMainContent ? 0 : 3}
          //width={hideMainContent ? 0 : '100%'}
          width="100%"
          padding={3}
          height="100%"
          overflow="auto"
          component="main"
          data-testid="dashboard-wrapper"
          sx={{
            opacity: transitioning ? 0 : 1,
            transition: 'opacity 0.2s',
            backgroundColor: theme.vars.palette.common.white,
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.grey[900],
            }),
          }}
        >
          {children}
        </Box>
      </Box>
      {showDebugFab ? (
        <Box
          position="fixed"
          bottom={theme.spacing(2)}
          right={theme.spacing(2)}
          zIndex={theme.vars.zIndex.fab}
        >
          <Fab color="primary" onClick={toggleDebugModalOpen}>
            <BugReportIcon />
          </Fab>
          {debugModalOpen ? (
            <Box
              sx={{
                backgroundColor: theme.vars.palette.grey[200],
                ...theme.applyStyles('dark', {
                  backgroundColor: theme.vars.palette.grey[600],
                }),
              }}
              padding={2}
              borderRadius={1}
              boxShadow={2}
              position="fixed"
              bottom={theme.spacing(10)}
              right={theme.spacing(2)}
              zIndex={theme.vars.zIndex.fab - 1}
              width={400}
            >
              <DebugModal hideForever={() => setHideFab(true)} />
            </Box>
          ) : null}
        </Box>
      ) : null}
      <AddDeviceModal
        open={showAddDeviceModal.open}
        groupId={showAddDeviceModal.groupId ?? undefined}
        onClose={closeAddDeviceModal}
      />
      <RemoveDeviceModal
        open={!!removeDeviceState}
        onClose={closeRemoveDeviceModal}
        serial={removeDeviceState}
      />
      <RemoveMultipleDevicesModal
        open={!!removeMultipleDevicesState}
        onClose={closeRemoveMultipleDevicesModal}
        serials={removeMultipleDevicesState}
      />
      <RemoveDeviceGroupModal
        open={!!removeDeviceGroupState}
        onClose={closeRemoveDeviceGroupModal}
        groupId={removeDeviceGroupState}
      />
      <RenameDeviceGroupModal
        open={!!renameDeviceGroupState}
        onClose={closeRenameDeviceGroupModal}
        groupId={renameDeviceGroupState}
      />
      <RebootDeviceModal
        open={!!rebootDeviceState}
        onClose={closeRebootDeviceModal}
        serial={rebootDeviceState}
      />
      <AddDeviceGroupModal
        open={showAddDeviceGroupModal !== false}
        preselectedDevices={showAddDeviceGroupModal as DeviceSerial[]}
        onClose={handleHideAddDeviceGroupModal}
      />
      <DeviceGroupConfigModal
        open={showDeviceGroupConfigModal.open}
        deviceSerialNumbers={
          showDeviceGroupConfigModal.deviceSerialNumbers ?? undefined
        }
        onClose={closeDeviceGroupConfigModal}
      />
      <TroubleshootingDevicesModal
        open={showDeviceTroubleshootingModal.open}
        deviceSerialNumber={
          showDeviceTroubleshootingModal.deviceSerialNumber ?? undefined
        }
        onClose={closeDeviceTroubleshootingModal}
      />
      <AddRfidModal
        open={showAddRfidModal.open}
        groupId={showAddRfidModal.groupId ?? undefined}
        onClose={closeAddRfidModal}
      />
      <UpdateRfidModal
        open={!!showUpdateRfidModal}
        rfidId={showUpdateRfidModal}
        onClose={closeUpdateRfidModal}
      />
      <RemoveRfidModal
        open={!!removeRfidState}
        onClose={closeRemoveRfidModal}
        rfidId={removeRfidState}
      />
      <RemoveMultipleRfidsModal
        open={!!removeMultipleRfidsState}
        onClose={closeRemoveMultipleRfidsModal}
        rfidIds={removeMultipleRfidsState}
      />
      <AddRfidGroupModal
        open={showAddRfidGroupModal !== false}
        preselectedRfids={showAddRfidGroupModal as RfidId[]}
        onClose={handleHideAddRfidGroupModal}
      />
      <RemoveRfidGroupModal
        open={!!removeRfidGroupState}
        onClose={closeRemoveRfidGroupModal}
        groupId={removeRfidGroupState}
      />
      <RenameRfidGroupModal
        open={!!renameRfidGroupState}
        onClose={closeRenameRfidGroupModal}
        groupId={renameRfidGroupState}
      />
      <PreferencesModal />
      <ProfileModal />
    </>
  );
};

export default DashboardWrapper;
