export const formatDynamicRoute = (
  urlPath: string,
  query: { [key: string]: string | string[] | undefined },
): string => {
  let recreatedPath = urlPath;

  if (query.id) {
    recreatedPath = recreatedPath.replace('[id]', query.id as string);
  }

  if (query.deviceType) {
    recreatedPath = recreatedPath.replace(
      '[deviceType]',
      query.deviceType as string,
    );
  }

  return recreatedPath;
};
